<template>
    <div class="all-photos">
        <div class="button-back f-f-sans" @click="back">
            <div class="arrow-back">
                <i class="iconfont iconarrow-left"></i>
            </div>
            <div class="div">
                <p>{{ $t("message.news.back") }}</p>
            </div>
        </div>
        <div class="gallery" ref="gallery">
            <ul>
                <li v-for="(item, index) in allPhotos" :key="index">
                    <img
                        loading="lazy"
                        class="item1"
                        :src="`${$url.news.host_image}` + allPhotos[index]"
                        alt=""
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
 
<script>
//import MagicGrid from "magic-grid";
export default {
    name: "all-photos",
    data: function () {
        return {
            newsID: "",
            content: "",
            allPhotos: [],
            numOfPhotos: 0,
        };
    },

    created() {
        const urlParams = new URLSearchParams(window.location.search);

        this.newsID = urlParams.get("id");

        this.getPhotos(this.newsID);
    },

    mounted() {},
    methods: {
        back: function () {
            this.$router.go(-1);
        },

        async getPhotos(id) {
            let response = await this.$http.get(
                `${this.$url.news.url_news_detail}?id=${id}`
            );

            this.content = response.data;
            this.allPhotos = this.content.picture.split(",");
            //this.numOfPhotos = 5;
        },
    },
};
</script>

<style lang="scss">
@mixin styling($unit-size) {
    .all-photos {
        .button-back {
            position: fixed;
            left: 27 * $unit-size;
            top: 72 * $unit-size;
            height: 0 * $unit-size;
            display: flex;
            flex-direction: row;
            padding: 0 0 0 0;
            height: 21 * $unit-size;
            width: 100vw/1920 * 98.45;
            border-radius: 4px;
            text-align: center;
            cursor: pointer;

            line-height: 21 * $unit-size;

            &:hover {
                background-color: rgba(229, 229, 229, 1);
            }
            font: Source Sans Pro;
            font-size: 11 * $unit-size;
            line-height: 21 * $unit-size;

            .arrow-back {
                height: 100vw/1920 * 13.71;
                width: 100vw/1920 * 9;
                margin-left: 20%;
                margin-right: 10%;
            }
        }
        padding: (118px) 0 (167px) 0;

        .gallery {
            width: 1020px;

            ul {
                //background-color: red;
                margin-left: auto;
                margin-right: auto;
                width: 1020px;
                display: flex;
                flex-flow: wrap;
                justify-content: space-between;
                li {
                    width: 500px;
                    height: 300px;

                    margin-bottom: 20px;
                }
            }
            margin-left: auto;
            margin-right: auto;

            img {
                width: 500px;
                height: 300px;
                object-fit: cover;

                display: flex;
                justify-content: center;
                align-items: center;

                //width: 500px;
                background-color: white;
            }
        }
    }
}
@media (max-width: 100vw) {
    @include styling($unit-size: 100vw/1366);
}
</style>